
import styles from './DesignThree.module.css';
import HeadingTwo from "../HeadingTwo";
import ParagraphOne from "../ParagraphOne";

function DesignThree({ additionalClassnames, name, description, alcoholPercentage, origin }) {
    return (
        <div className={styles.DesignThree}>
            {/* Category */}

            <div className={styles.categoryItem}>

            </div>

            <div className={styles.drinkList}>
                <HeadingTwo>{name}</HeadingTwo>
                <ParagraphOne>{description}</ParagraphOne>
                <ParagraphOne>{alcoholPercentage}</ParagraphOne>
                <ParagraphOne>{origin}</ParagraphOne>

            </div>
        </div>
    )

}

export default DesignThree; 