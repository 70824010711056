export function toastOnlineOrderLink() {
    window.location.href = 'https://order.toasttab.com/online/taco-tequila-y-mas-31660-john-r-road';
}

export function googleMapsLink() {
    window.location.href = "https://maps.app.goo.gl/PG7ByyR1aJG32buBA";
}

export function reservationsLink() {
    alert("Feature Coming Soon!")
}