import React from 'react';

function NoPage() {
    return ( 
        <div>
            <h1>NO PAGE FOUND</h1>
        </div>
     );
}

export default NoPage;